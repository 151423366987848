<template>
  <div>
    <div v-if="loaded">
      <div class="select-none max-w-7xl mx-auto">
        <div class="mx-4">
          <div
            :class="!notLoaded ? '' : 'hidden'"
            class="text-2xl text-body-4 mt-12 font-medium"
          >
            <p>Doklady pro nemovitost</p>
            <p class="text-body-1 text-3xl pt-2">{{ currentProperty.name }}</p>
          </div>
          <div v-if="notLoaded">
            <div class="font-bold text-body-1 text-center mt-10 mb-10">
              Nemáte žádné zakoupené služby
            </div>
          </div>
          <div>
            <table class="w-full mt-12" :class="!notLoaded ? '' : 'hidden'">
              <th class="w-1/6 text-left">Název</th>
              <th class="w-1/6 text-left">Cena</th>
              <th class="w-1/6 text-right">Doklad</th>
            </table>
            <table
              v-for="receipt in receipts"
              :key="receipt.id"
              class="table-fixed mt-4 w-full"
            >
              <tbody class>
                <tr class="border-t-2">
                  <td class="py-4 text-17 text-body-4 font-bold">
                    {{ receipt.description }}
                  </td>
                  <td class="py-4 text-17 text-body-4 font-bold">
                    {{ `${receipt.data.amount.toString().slice(0, -2)} Kč` }}
                  </td>
                  <td class="py-4 text-17 font-bold text-right cursor-pointer">
                    <router-link :to="`/doklad/${receipt.id}`" target="_blank">
                      <span
                        class="text-body-1 hover:bg-blue-100 rounded px-4 py-4"
                      >
                        <img
                          src="/images/download 1.png"
                          class="inline pb-1 pr-2"
                        />{{ receipt.ticket ? " Stvrzenka" : " Daňový doklad" }}
                      </span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loaded">
      <div class="font-bold text-body-1 text-center mt-10 mb-10">
        Načítám data...
        <div class="grid justify-items-center">
          <img src="../../../public/images/dataLoading.gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGIN } from "@/store/user/constants";
import { getPropertyInvoices } from "../../services/rest/services";

export default {
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("main", ["currentProperty"]),
  },
  data() {
    return {
      receipts: [],
      loaded: false,
      notLoaded: true,
      houseTour: [
        "1x 3D Model",
        "1x Profesionální fotografie",
        "1x Profesionální video",
      ],
    };
  },
  methods: {
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
  },
  async mounted() {
    // Stripe getData by currentPropertyId MODULE
    const receipts = await getPropertyInvoices(this.currentProperty.id);
    if (receipts) {
      this.receipts = receipts.map(receipt => {
        return {
          ...receipt,
          ticket: this.houseTour.includes(receipt.description)
        }
      });
    }

    this.loaded = !this.loaded;

    if (this.loaded === true) {
      if (this.receipts.length > 0) {
        this.notLoaded = !this.notLoaded;
      } else return;
    }

    return;
  },
};
</script>
